<!--
 * @Author: wyq
 * @Date: 2021-09-27 16:03:55
 * @LastEditTime: 2021-10-22 16:35:35
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\questionContainer.vue
-->
<template>
  <div class="question-container">
    <div class="question-container-left">
      <timer ref="currentTimer"></timer>
      <v-divider light></v-divider>
      <question-stem :questionInfo="questionInfo"></question-stem>
    </div>
    <div class="question-container-right">
      <div v-show="questionInfo.type == 1 || questionInfo.type == 4" class="content">
        <question-options :questionInfo="questionInfo" :currentOption.sync="currentOption"></question-options>
      </div>
      <div v-show="questionInfo.type == 2" class="content">
        <div @click="show = !show">
          <math-render :value.sync="fillupAnswer" :imgshow="!show"></math-render>
        </div>
        <math-keyboard :show.sync="show" :value.sync="fillupAnswer"></math-keyboard>
      </div>
      <div class="action-bar">
        <v-btn large text color="#999" @click="showJumpOver" :loading="loading">跳过此题</v-btn>
        <v-btn large color="primary" :disabled="!(answer !== '')" @click="submitAnswer" :loading="loading">提交</v-btn>
      </div>
    </div>
    <timer ref="totalTimer" class="timer" type="timer" :totalMinute="$store.state.catTesting.totalTime"
      @timeOut="timeOut"></timer>
    <jump-over-msg-box :visible.sync="jumpOverVisible" @submit="jumpOverQuestion"></jump-over-msg-box>
  </div>
</template>
<script>
import timer from './timer.vue'
import questionStem from './questionStem.vue'
import questionOptions from './questionOptions'
import jumpOverMsgBox from '../../components/dialogs/jumpOverMsgBox'
export default {
  components: {
    timer,
    questionStem,
    questionOptions,
    jumpOverMsgBox,
  },
  props: {
    questionInfo: Object,
    loading: Boolean,
  },
  data() {
    return {
      show: false,
      fillupAnswer: '',
      jumpOverVisible: false,
      currentOption: [],
    }
  },
  watch: {
    'questionInfo.questionId'() {
      this.init()
    },
  },
  computed: {
    answer() {
      let answer = ''
      if (this.questionInfo.type == 1 || this.questionInfo.type == 4) {
        answer = this.currentOption ? this.currentOption.join(',') : ''
      } else if (this.questionInfo.type == 2) {
        answer = this.fillupAnswer
      }
      return answer
    },
  },
  methods: {
    init() {
      this.fillupAnswer = ''
      this.currentOption = null
    },
    getTimer() {
      return {
        currentTimer: this.$refs.currentTimer,
        totalTimer: this.$refs.totalTimer,
      }
    },
    showJumpOver() {
      this.jumpOverVisible = true
    },
    jumpOverQuestion() {
      this.$emit('submitAnswer', '')
    },
    submitAnswer() {
      this.$emit('submitAnswer', this.answer)
    },
    timeOut() {
      this.$emit('timeOut', this.answer)
    },
  },
  mounted() { },
}
</script>
<style lang="scss" scoped>
@import '../../sass/question.scss';
</style>