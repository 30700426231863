<!--
 * @Author: wyq
 * @Date: 2021-07-28 15:33:21
 * @LastEditTime: 2021-10-11 12:53:32
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\timer.vue
-->
<template>
  <div v-if="type=='timer'" class="timer" :class="{'danger':isDanger}">
    <v-icon>icon-timer</v-icon>
    <span>{{str}}</span>
  </div>
  <div v-else class="clock" :style="{color}">
    <v-icon :color="color">icon-clock</v-icon>
    <span>{{str}}</span>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'clock',
    },
    totalMinute: {
      // 总时间（分钟） 0表示不倒计时
      type: Number,
      default: 0,
    },
    countDownMinute: {
      // 剩余时间倒计时（分钟）
      type: Number,
      default: 5,
    },
    color: {
      type: String,
      default: '#999',
    },
  },
  data() {
    return {
      hour: 0, //定义时，分，秒，毫秒并初始化为0；
      minute: 0,
      ms: 0,
      second: 0, //秒
      time: '',
      str: '00:00:00',
      isDanger: false,
    }
  },

  methods: {
    // 开始、继续
    start() {
      if (this.time) {
        clearInterval(this.time)
      }
      this.time = setInterval(this.timer, 500)
    },

    // 暂停
    stop() {
      console.log('暂停')
      clearInterval(this.time)
    },

    //重置
    reset() {
      clearInterval(this.time)
      this.hour = 0
      this.minute = 0
      this.ms = 0
      this.second = 0
      this.str = '00:00:00'
    },

    //定义计时函数
    timer() {
      this.ms = this.ms + 500 //毫秒
      if (this.ms >= 1000) {
        this.ms = 0
        this.second = this.second + 1 //秒
      }
      if (this.second >= 60) {
        this.second = 0
        this.minute = this.minute + 1 //分钟
      }
      if (this.minute >= 60) {
        this.minute = 0
        this.hour = this.hour + 1 //小时
      }
      if (this.totalMinute) {
        // 判断是否开始倒计时
        this.isDanger =
          this.totalMinute - this.countDownMinute <=
          this.hour * 60 + this.minute
        if (this.totalMinute == this.hour * 60 + this.minute) {
          this.stop()
          // 计时结束
          this.$emit('timeOut')
        }
        console.log()
      }
      this.str =
        this.toDub(this.hour) +
        ':' +
        this.toDub(this.minute) +
        ':' +
        this.toDub(this.second) /*+""+this.toDubms(this.ms)+"毫秒"*/
      // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
    },

    //补0操作
    toDub(n) {
      if (n < 10) {
        return '0' + n
      } else {
        return '' + n
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 60px;
  line-height: 60px;
  background: #ffffff;
  font-size: 28px;
  box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px 10px 10px 20px;
  border: 2px solid #deedfc;
  color: #deedfc;
  font-family: Akrobat-Semibold;
  .v-icon {
    color: #deedfc;
    margin-right: 8px;
  }
  &.danger {
    border: 2px solid #ff6262;
    color: #ff6262;
    .v-icon {
      color: #ff6262;
    }
  }
}
.clock {
  display: inline-flex;
  height: 76px;
  line-height: 76px;
  font-size: 28px;
  color: #999;
  font-family: Akrobat-Semibold;
  .v-icon {
    color: #999;
    margin-right: 8px;
  }
}
</style>