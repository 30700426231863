<!--
 * @Author: wyq
 * @Date: 2021-07-26 15:57:59
 * @LastEditTime: 2021-09-29 15:15:12
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\jumpOverMsgBox.vue
-->
<template>
  <msg-box-template
    :visible.sync="dialogVisible"
    title="跳过此题"
    :imgUrl="require('@/assets/images/dialog/pass.png')"
  >
    是否确定跳过此题？
    <br />跳过题目后将无法返回重新作答
    <template slot="btnGroup">
      <v-btn color="error" depressed x-large outlined @click="submit" :loading="submitLoadding">确定跳过</v-btn>
      <v-btn color="primary" depressed x-large @click="close">我再想想</v-btn>
    </template>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
</style>